










































import { Component, Vue } from 'vue-property-decorator';

import { ISiteTrafficTaskUpdate } from '@/interfaces/site-traffic';
import {
  dispatchGetSiteTrafficTasks,
  dispatchUpdateSiteTrafficTask,
} from '@/store/site-traffic/actions';
import { readOneSiteTrafficTask } from '@/store/site-traffic/getters';
import { nameRules } from './validators';

@Component
export default class AvitoEditTask extends Vue {
  public submitting = false;
  public valid = false;
  public name = '';
  public description = '';
  public domains: string[]|null = null;
  public nameRules = nameRules;

  public async mounted() {
    this.reset();  // чтобы сразу показать в полях значения, которые уже загружены
    await dispatchGetSiteTrafficTasks(this.$store);
    this.reset();  // обновляем значения полей, если они изменились
  }

  public reset() {
    if (this.task) {
      this.name = this.task.name;
      this.description = this.task.description;
    } else this.name = this.description = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      this.submitting = true;
      const updatedTask: ISiteTrafficTaskUpdate = {
        name: this.name,
        description: this.description || undefined,
      };
      await dispatchUpdateSiteTrafficTask(this.$store, { id: this.task!.id, task: updatedTask });
      this.$router.push('/main/site-traffic');
    }
  }

  get task() {
    return readOneSiteTrafficTask(this.$store)(+this.$router.currentRoute.params.id);
  }

  public get isNew() {
    return this.task?.status === 'NEW';
  }

}
